.container-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
  background-color: white;
}

.content-footer-imageCatskilletLogo {
  width: 23.12px;
  height: 15.66px;
  margin-right: 7.9px;
}

.container-imageGoogleIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.content-imageGoogleIcon {
  width: 16.51px;
  height: 17.56px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.container-imageSlackLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 13px;
}

.content-imageSlackLogo {
  width: 21.69px;
  height: 18.74px;
}

.container-footer-left {
  display: flex;
  align-items: center;
}

.container-footer-left-siteCatskillet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 34px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-left: 33px;
  margin-right: 13px;
}

.container-footer-left-link {
  text-decoration: none;
}

.container-footer-left-link > span {
  font-weight: 600;
  font-size: 13.422px;
  line-height: 14px;
  color: #000000;
}

.container-footer-rigth {
  display: flex;
  align-items: center;
}

.container-footer-rigth > span {
  font-size: 18px;
  color: #333333;
  margin-right: 44px;
  margin-left: 16px;
}

@media (max-width: 768px) {
  .container-footer {
    justify-content: center;
    width: 100%;
  }

  .container-footer-rigth > span {
    display: none;
  }

  .container-phoneIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    margin-left: 16px;
  }

  .container-footer-left-link span {
    display: none;
  }

  .container-footer-left-siteCatskillet {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    margin-left: 0px;
  }
}

.form-field {
  .advanced-select {
    > div {
      border-radius: 15px;
      border-color: #e8e9eb;
    }
  }

  .css-319lph-ValueContainer {
    padding-left: 20px;
    font-size: 14px;
    color: #3f434a;
    font-family: "Open Sans", sans-serif !important;
  }

  .css-tlfecz-indicatorContainer {
    width: 38px;
    padding-right: 15px;
    color: #3f434a;
  }

  .css-1okebmr-indicatorSeparator {
    visibility: hidden;
  }

  .css-109onse-indicatorSeparator {
    visibility: hidden;
  }
}

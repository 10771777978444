.container-calendar {
  width: 100%;
  box-shadow: none;
}

// header
.calendar-header {
  display: flex;
  justify-content: center;
  background-color: #86c9fd;
  height: 50px;
  align-items: center;

  .calendar-current {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
  }

  .arrow-left {
    width: 10px;
    margin-right: 35px;
    cursor: pointer;
  }

  .arrow-right {
    width: 10px;
    margin-left: 35px;
    cursor: pointer;
  }
}

.calendar-days {
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #8a9099;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: white;

  .letter {
    font-family: "Open Sans", sans-serif !important;
    width: calc(100% / 5);
  }

  .name {
    display: none;
  }

  .hide {
    display: none;
  }
}

.calendar-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .calendar-row {
    width: 100%;
    position: relative;
    display: flex;
    border-top: 1px solid #e8e9eb;
    border-bottom: 1px solid #e8e9eb;

    &:first-child {
      border-top: 1px solid #e8e9eb;
    }
  }

  .calendar-day {
    position: relative;
    width: 110%;
    transition: all ease-in 0.2s;
    background-color: white;

    & > div {
      width: 100%;
      padding-bottom: 120%;
      display: flex;
      justify-content: center;
      border-right: 1px solid #e8e9eb;

      &:first-child {
        border-left: 1px solid #e8e9eb;
      }
    }

    span {
      position: absolute;
      font-family: "Poppins", sans-serif;
      color: #3f434a;
      font-size: 12px;
      line-height: 12px;
      top: calc(50% - 6px);
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(#304ffd, 0.03);
    }

    &.disabled {
      display: none;
    }
  }

  .selected span {
    display: flex;
    justify-content: center;
    width: 21px;
    height: 18px;
    background: #fc473d;
    border-radius: 5px;
    color: white;
    padding-top: 3px;
  }

  .incomplete {
    background-color: rgba(#fc473d, 0.2);
  }

  .complete {
    background-color: rgba(green, 0.2);
  }

  .default {
    background-color: white;
    
  }

  .finished  {

    .selected span{
      color: #3f434a !important;
      height: unset !important;
    }
    
      pointer-events: none;

    span {
      background-color: transparent;
    }

    &:after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background: url(../../images/locker.png) no-repeat center center;
      background-size: contain;
      opacity: 0.3;
      position: absolute;
      bottom: 5px;
      left: 5px;
    }
  }

  .disabled {
    &:after {
      display: none;
    }
  }

  .blue {
    background-color: rgba(blue, 0.2);
  }
}

@media screen and (min-width: 768px) {
  .container-calendar {
    width: 100%;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }

  .calendar-header {
    height: 74px;

    .calendar-current {
      font-size: 18px;
      text-transform: uppercase;
    }

    .arrow-left {
      width: 18px;
      margin-right: 35px;
    }

    .arrow-right {
      width: 18px;
      margin-left: 35px;
    }
  }

  .calendar-days {
    display: flex;
    justify-content: space-around;
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    color: #8a9099;
    padding-top: 16px;
    padding-bottom: 15px;
    background-color: white;

    .name {
      font-family: "Open Sans", sans-serif !important;
    }

    .letter {
      display: none;
    }

    .name {
      display: block;
    }

    .show {
      display: flex;
    }
  }

  .calendar-content {
    .calendar-day {
      width: 80%;

      &.disabled {
        display: flex;
      }

      & > div {
        display: block;
        padding-bottom: 80%;
      }

      span {
        top: 12px;
        right: 12px;
        font-size: 20px;
        padding: 7px;
      }

      .selected span {
        align-items: center;
        width: 43px;
        height: 38px;
        border-radius: 14px;
      }
    }
  }
}

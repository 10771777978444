.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 32px;
  border: none;
  border-radius: 0;
  font-size: 12px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

.btn.btn-custom {
  border: none;
  border-radius: 0;
  font-size: 15px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;

  &.--red {
    color: #ffffff;
    background: var(--default-red);
  }
}

.btn.btn-primary {
  background-color: var(--default-red);
}

.btn.btn-secondary {
  background-color: white;
  color: var(--default-red);
  border: 1px solid var(--default-red);
  margin-left: 8px;
}

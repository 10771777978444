.modal-alert {
  .modal-content {
    align-items: center;
    width: 100%;
    max-width: 460px;
    height: 100%;
    margin: 0;
    background-color: transparent;
    border: none;
  }

  .modal-dialog {
    max-width: 460px;
    margin-left: auto;
    margin-right: 0;
  }

  .modal-header {
    width: 283px;
    background: white;
    padding-top: 20px;
    padding-top: 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .modal-header button {
    color: red;
  }

  .modal-body {
    width: 100%;
    max-width: 275px;
    background: white;
    padding: 0;
    overflow: auto;

    h3 {
      font-family: "Lato", sans-serif;
      font-weight: bold;
      font-size: 22px;
      color: #333333;
      line-height: 30px;
    }

    button.label-close {
      display: flex;
      justify-content: center;
      margin-top: 48px;
      font-family: "Lato", sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: var(--default-red);
      width: 119px;
      height: 47px;
      border: none;
      text-decoration: underline;
      text-decoration: center;
      background-color: white;
      padding-left: 137px;
    }
  }

  li > h2 {
    font-weight: bold;
    font-size: 15px;
    margin-top: 20px;
  }

  li > p {
    font-size: 12px;
    margin-bottom: 6px;
  }
}

.dashboard-wrapper {
  padding-top: 97px;
  padding-bottom: 30px;
}

.dashboard-subtitle {
  display: flex;
  justify-content: center;
  padding-top: 23px;
}

.navigation-canvas {
  // $btn-close-color: rgba(0,0,0,1);
  // $btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");

  border: none !important;
  background-color: #f4f4f4 !important;
  padding-top: 65px;

  &__header.offcanvas-header {
    padding: 0;
    position: inherit;

    .btn-close {
      padding: 30px;
      display: block;
    }
  }

  &__content {
    padding: 10px 0 40px 0;
    margin: 0 auto;
    width: 100%;
    max-width: 310px;
  }

  @media screen and (min-width: 1366px) {
    &__content {
      padding: 10px 0 40px 0;
      margin: 0 auto;
      width: 100%;
      max-width: 310px;
    }
  }
}

@media (max-width: 768px) {
  .row > * {
    padding: 0 !important;
  }

  .dashboard-wrapper {
    padding-top: 65px;
    padding-bottom: 0;
  }

  .dashboard-subtitle {
    display: none;
  }

  .booktime-content {
    margin-bottom: 18px;
  }

  .navigation-canvas {
    &__content {
      padding: 10px 0 40px 0 !important;
    }
  }
}

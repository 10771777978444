.list-hours {
  padding: 0;
  min-width: 273px;
  list-style: none;

  li > label {
    width: 100%;
    cursor: pointer;
  }
}

.hour-added-container {
  width: 273px;
  min-height: 75px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 0.84px solid #ececec;
  background-color: white;
  box-shadow: 1.67241px 2.50861px 5.01722px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-left: 14px;
  margin-bottom: 10px;
  padding-bottom: 11px;
}

.btn-edit {
  border: none;
  background-color: white;
  padding: 0px;
}

.btn-edit span {
  text-decoration: underline;
  padding-left: 5.1px;
  font-size: 12px;
  color: #333333;
}

.btn-edit img {
  width: auto;
  height: 10px;
}

.form-check-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.red {
    .hour-added-container {
      border: 0.84px solid #fc473d;
    }
  }
}

.form-check-label {
  display: flex;
  align-items: center;
}

.hour-added-content-left h2 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-top: 11px;
}

.hour-added-content-left p {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.hour-added-content-right span {
  font-size: 14px;
  color: #333333;
}

.modal-title {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  font-family: Lato;
}

li > h2 {
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
}

.button-disabled {
  background-color: #bdbdbd !important;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  width: 283px;
  height: 60px;
  background-color: white;
  height: 50px;
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

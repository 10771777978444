body {
  background-color: #f8f8f8;

  * {
    font-family: "Margem", sans-serif;
  }

  .navigation-canvas {
    width: 100%;
    max-width: 460px;

    .offcanvas-body {
      padding: 0;
    }
  }
}

.page-default {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100vw;
  min-height: 100vh;
  padding-bottom: 53px;
  position: relative;
}

.disabled {
  pointer-events: none;
  background: rgba(0, 0, 0, 0.1) !important;
}

.disabled-custom {
  pointer-events: none;
  background: rgba(207, 205, 205, 0.1) !important;
}

.custom-label {
  color: #8a9099;
  margin-bottom: 15px;
}

.header-container {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 65px;
  background-color: white;
  z-index: 1300;
}

.content-header-left-image {
  width: 58.19px;
  height: 42px;
  margin-left: 47px;
}

.content-header-rigth {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu.show {
  transform: translate(2.1111px, 48.4444px) !important;
  border: none;
  border-radius: 0;
  padding: 0;
}

.content-header-rigth-bell {
  width: 22px;
  height: 22px;
}

.content-header-profile-picture {
  width: 43px;
  height: 43px;
  margin-left: 43px;
  border-radius: 50%;
  background-color: red;
  justify-content: center;
  align-items: center;
  display: flex;
}

.content-header-profile-picture a {
  text-decoration: none;
}

.content-header-profile-picture span {
  max-width: 100%;
  border-radius: 50%;
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
}

#dropdown-basic-button {
  font-family: Open Sans;
  font-size: 15px;
  font-weight: bold;
  background-color: white;
  color: #3f434a;
  margin-right: 30px;
  margin-left: 17px;
}

.dropdown-item {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.horizontal-bar {
  padding-top: 9px;
}

a.dropdown-item > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 12px;
  color: #3f434a;
  font-family: Open Sans;
  text-decoration: underline;
}

.content-header-barImage {
  margin-left: 23.85px;
  background: #e8e9eb;
}

.content-header-barImage-loggin {
  background: #e8e9eb;
  margin-right: 35px;
}

.button-logout {
  background-color: white;
  border: none;
  margin-right: 10px;
}

.logout-image {
  width: 15px;
  height: auto;
}

.dropdown-toggle::after {
  margin-left: 12px !important;
}

@media (max-width: 768px) {
  .content-header-left-image {
    width: 44.34px;
    height: 32px;
    margin-left: 15.93px;
  }

  .content-header-rigth-bell {
    width: 20px;
    height: 20px;
  }

  .content-header-rigth-bell-container {
    width: 15.55px;
    height: 15.55px;
    margin-bottom: 10px;
  }

  .content-header-barImage {
    margin-left: 16.85px;
  }

  .content-header-profile-picture {
    width: 30.39px;
    height: 30.39px;
    margin-left: 14.61px;
  }

  .content-header-profile-picture span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 10.601px;
    display: flex;
    align-items: center;
  }

  .button-logout {
    display: none;
  }

  .content-header-name {
    font-size: 10.601px;
    font-family: Open Sans;
    font-weight: bold;
    margin-left: 12.01px;
    margin-right: 33.78px;
  }

  .content-header-barImage-loggin {
    display: none;
  }
}


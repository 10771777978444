.wrapper-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  min-height: calc(100vh - 53px);
  background-image: url("../../images/circulosMobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.container-content {
  z-index: 3;
  position: relative;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .effect {
    z-index: 10;
  }

  @media screen and (min-width: 320px) {
    width: 300px;
    height: 300px;
  }
  
  @media screen and (min-width: 375px) {
    width: 320px;
    height: 320px;
  }

  @media screen and (min-width: 425px) {
    width: 360px;
    height: 360px;
  }

  @media screen and (min-width: 520px) {
    width: 420px;
    height: 420px;
  }

  @media screen and (min-width: 768px) {
    width: 520px;
    height: 520px;
  }
}

.content-imageCatskilletLogo {
  width: 114px;
  height: 82px;
  margin-bottom: 31px;
}

.content-text-doLogin {
  font-style: normal;
  font-weight: bold;
  font-size: 9.7px;
  line-height: 100.6%;
  text-align: center;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  color: #4f4f4f;
  margin-top: 0;
  margin-bottom: 20px;
}

.botao-login-google {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 304px;
  height: 30px;
  background-color: white;
  border-radius: 8.5px;
  border: 1px solid #828282;
}

.container-content-bookYourTime {
  display: flex;
  align-items: center;
}

.content-bookYourTime-imageBolinha {
  width: 6.72px;
  height: 6.72px;
}

.content-bookYourTime-text {
  font-size: 11px;
  font-weight: bold;
  color: #4f4f4f;
  margin-left: 12px;
  margin-right: 8px;
  margin-top: 8px;
}

.content-bookYourTime-imageAcentoTil {
  width: 20px;
}

.content-login {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30.53px;
  margin-bottom: 18.3px;
  border-radius: 8.5px;

  .content-login-image {
    width: 12px;
    height: 12px;
    margin-right: 14.5px;
  }

  .content-login-text {
    color: #595f69;
    font-size: 12px;
    margin-top: 9.55px;
  }
}

@media screen and (min-width: 768px) {
  .container-content {
    padding: 70px 40px;
    width: 540px;
    height: 540px;
  }

  .wrapper-content {
    background-image: url("../../images/circulos.png");
  }

  .content-imageCatskilletLogo {
    width: 187px;
    height: 135px;
    margin-bottom: 51px;
  }

  .content-text-doLogin {
    font-size: 16px;
    letter-spacing: 4px;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .botao-login-google {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    border: none;
    background-color: white;
    border: 1px solid #828282;
    border-radius: 14px;
    display: flex;
  }

  .container-content-bookYourTime {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  .content-bookYourTime-imageBolinha {
    width: 11px;
    height: 11px;
  }

  .content-bookYourTime-text {
    font-size: 18px;
    font-weight: bold;
    color: #4f4f4f;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 8px;
  }

  .content-bookYourTime-imageAcentoTil {
    width: 35px;
  }

  .content-login {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30.53px;
    margin-bottom: 18.3px;
    border-radius: 8.5px;

    .content-login-image {
      width: 20px;
      height: 20px;
      margin-right: 14.5px;
    }

    .content-login-text {
      color: #595f69;
      font-size: 19.5px;
      margin-top: 10px;
    }

    .botao-login-google {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 498px;
      height: 50px;
      background-color: white;
      border-radius: 14px;
      border: 1px solid #828282;
    }
  }
}

.header-dashboard {
  display: flex;
  flex-direction: row;
  //   align-items: center;

  & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
  }

  &__date {
      * {
        color: #fc473d; 
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 20px;
      margin-right: 25px;
      border-right: 1px solid #86C9FD;
  }
  &__day {
      font-weight: bold;
    font-size: 50px;
    line-height: 50px;
  }
  &__month {
      font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }


  &__user {
    display: flex;
    align-items: center;

    h3{
        font-weight: bold;
        color: #fc473d; 
        margin-bottom: 0;
        padding-left: 10px;
    }
  }

  &__subtitle {
      margin-top: 5px;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
    color: #4f4f4f;
  }
  
}

.notifications-container {
  width: 100%;
}

.notifications-content-image {
  margin-bottom: 399px;
  width: 100%;
  height: 550px;
}

@media (max-width: 768px) {
  .notifications-content-image {
    height: 385px;
    margin-bottom: 284px;
    position: absolute;
  }
}

.book-time {
  margin-top: 30px;

  .nav-tabs {
    width: 100%;
    margin-bottom: 22px;

    li {
      width: 50%;
    }

    button {
      padding: 12px 20px;
      border: 0;
      border-radius: 0;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      color: #5B5B5B;
      border: 0.755427px solid #A4A4A4;
      
      &.active {
        background-color: var(--default-red);
        color: #fff;
        border: 0.755427px solid var(--default-red);
      }
    }
  }

  .book-time-total-hours {
    display: flex;
    justify-content: space-evenly;

    h3 {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .btn-submit {
    width: 100%;
    max-width: 151px;
    height: 40px;
    margin: 30px auto 0 auto;

    &:disabled,
    &[disabled="disabled"],
    &[disabled="true"]{
      background-color: var(--default-body)!important;
      pointer-events: none;
    }
  }
}




* {
  font-family: "Margem", sans-serif;
}

.notification-menu-cotainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 482px;
  max-height: 60%;
  background-color: #f2f2f2;
  border-top-left-radius: 21px;
  border-top-right-radius: 21px;
}

.notification-menu-header-cotainer {
  display: grid;
  width: 482px;
  height: 88px;
  background-color: white;
  border-top-left-radius: 21px;
  border-top-right-radius: 21px;
}

.notification-menu-header-left-content {
  display: flex;
  align-items: center;
  margin-left: 34px;
  margin-right: 34px;
  justify-content: space-between;
}

.notification-menu-header-left-content-bell {
  width: 31px;
  height: 31px;
}

.notification-menu-header-rightclosebutton {
  width: 0px;
  height: 60px;
  margin-left: 26px;
}

.rightclosebutton {
  opacity: 40%;
  background-color: #bdbdbd;
  width: 18px;
  height: 18px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.notification-menu-header-left-content h1 {
  font-weight: bold;
  font-size: 25px;
  color: #3f434a;
  margin-top: 7px;
}

.notification-menu-list {
  background-color: #f2f2f2;
  max-height: 602px;
  overflow-y: scroll;
  border-bottom-left-radius: 21px;
}

.notification-menu-list::-webkit-scrollbar {
  width: 9px;
  background-color: #e0e0e0;
  border-radius: 30px;
}

.notification-menu-list::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 30px;
}

.notification-menu-list-item {
  width: 100%;
  height: 76px;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.notification-menu-list-item p {
  margin-top: 16px;
  font-size: 14px;
  color: #252733;
  margin: 0px;
}

.notification-menu-list-status-red {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #eb5757;
  margin-right: 21px;
  margin-left: 35px;
}

@media (max-width: 768px) {
  .notification-menu-cotainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 329.28px;
    max-height: 60%;
    margin-top: 0px;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
  }

  .notification-menu-header-cotainer {
    width: 329.28px;
    height: 61.65px;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
  }

  .notification-menu-list {
    max-height: 504.43px;
    border-radius: 21px;
  }

  .notification-menu-list-status-red {
    width: 18.42px;
    height: 18.42px;
    margin-right: 14.71px;
    margin-left: 21.72px;
  }

  .notification-menu-list-item {
    height: 76px;
  }

  .notification-menu-list-item p {
    font-size: 10px;
  }

  .notification-menu-header-left-content-bell {
    width: 22px;
    height: 22px;
  }

  .notification-menu-header-rightclosebutton {
    width: 0px;
    height: 40px;
    margin-left: 21px;
  }

  .rightclosebutton {
    width: 15px;
    height: 15px;
  }

  .notification-menu-header-left-content h1 {
    font-size: 17.5px;
    margin-top: 12px;
  }
}

@media screen and (min-width: 1100px) {
  .notification-menu-cotainer {
    top: 50%;
  }
}

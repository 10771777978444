.modal-alert {
  .modal-content {
    align-items: center;
    width: 100%;
    max-width: 460px;
    height: 100%;
    margin: 0;
    background-color: transparent;
    border: none;
  }

  .modal-dialog {
    max-width: 460px;
    margin-left: auto;
    margin-right: 0;
  }

  .modal-body {
    width: 100%;
    max-width: 283px;
    max-height: 300px;
    background: white;
    padding: 0;
    overflow: auto;

    h3 {
      font-family: "Lato", sans-serif;
      font-weight: bold;
      font-size: 17px;
      color: #333333;
    }

    h6 {
      display: flex;
      justify-content: center;
      margin-top: 48px;
      font-family: "Lato", sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: var(--default-red);
      text-decoration: underline;
    }
  }

  .modal-body::-webkit-scrollbar {
    width: 10px;
    background-color: #e0e0e0;
  }

  .modal-body::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 20px;
  }
}

.modal-footer {
  padding: 0 !important;
  border: none !important;
}

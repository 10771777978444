.subtitle-container {
  display: flex;
  justify-content: center;
}

.subtitle-content {
  display: flex;
  align-items: center;
  margin-left: 33px;
}

.subtitle-text {
  font-size: 14px;
  color: #333333;
  margin-top: 5px;
}

.subtitle-status-current {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 15px;
  margin-bottom: 14px;
  background-color: rgba(green, 0.2);
}

.subtitle-status-not-applied {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 15px;
  margin-bottom: 14px;
  background-color: rgba(252, 71, 61, 0.2);
}

.subtitle-status-applied {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 15px;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

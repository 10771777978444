.form-add {
  label {
    color: var(--default-body);
    font-size: var(--font-size);
    margin-bottom: 15px;
  }
}

.spinner-centered {
  display: flex;
  justify-content: center;
}

.text-center {
  width: 160px;
  margin-top: 46px;
  margin-left: 57px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 17px;
  color: #333333;
}

.form-field {
  position: relative;
}
.form-field__alert {
  position: absolute;
  font-size: 12px;
  color: var(--default-red);
  bottom: -18px;
}

.custom-edit-btns {
  display: flex;
  justify-content: space-between;
}

.input-time-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.input-time-wrapper span{
  font-size: 24px;
}

.input-time-wrapper select{
  max-width: 120px;
  min-width: 120px;
  font-size: 18px;
  height: 36px;
  padding: 0 14px;
  border-radius: 36px;
  border-color: #e8e9eb;
  border-radius: 15px;
  padding-left: 45px;
}


.input-time {
  button {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;

    border-radius: 40px;
    color: #fff;
    background-color: var(--default-red);
    text-align: center;
    font-size: 30px;
    line-height: 1;

    &.disabled {
      background-color: var(--default-body);
    }

    img {
      max-width: 20px;
    }
  }

  &__track {
    background-color: #fff;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.btn-cancel {
  background: #86c9fd !important;
}
